import React, { useState, useRef, useEffect } from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Icon from '@nubank/nuds-web/components/Icon/Icon';
import { useBreakpointsMediaUp } from '@nubank/nuds-web/styles/breakpoints';
import { createPortal } from 'react-dom';

import ProductTitle from './ProductTitle';
import CreditoRates from './CreditoRates';
import RatesModal from './RatesModal';

const Credito = () => {
  const [showCredito, setShowCredito] = useState(false);
  const boxPosition = useRef();
  const isDesktop = useBreakpointsMediaUp('lg');
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // This code will only execute in the browser environment
      if (isOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'visible';
      }
    }
  }, [isOpen]);

  function showLessButton() {
    window.scrollTo({
      top: boxPosition.current.offsetTop - 200,
    });

    setShowCredito(false);
  }

  const showMore = () => {
    if (isDesktop) {
      setShowCredito(false);
      setIsOpen(true);
    } else {
      setShowCredito(true);
    }
  };

  return (
    <Box
      backgroundColor="#F8F8FA"
      borderRadius="12px"
      paddingHorizontal={{ xs: '8x', lg: '4x' }}
      paddingVertical={{ xs: '8x', lg: '4x' }}
      marginHorizontal={{ xs: '8x', lg: ' 0' }}
      marginTop={{ xs: '15px', md: '3%' }}
      display="flex"
      flexDirection="column"
      justifyContent={{ xs: 'left', md: 'center' }}
      width="-webkit-fill-available"
      minHeight={{ xs: 'auto', lg: 'inherit' }}
    >
      <Box
        ref={boxPosition}
        display="flex"
        flexDirection="row"
        justifyContent={{ xs: 'left', lg: 'center' }}
        alignItems="center"
      >
        <ProductTitle title="credito" />
      </Box>

      { showCredito ? <null />
        : (
          <Box
            display="flex"
            justifyContent={{ xs: 'flex-start', lg: 'center' }}
            paddingRight={{ xs: '40px', md: '0' }}
            marginTop={{ xs: '2x', lg: '3x' }}
            alignItems="center"
            onClick={() => { showMore(); }}
            style={{ cursor: 'pointer' }}
          >
            <Typography
              intlKey="RATES.CREDIT.LINK"
              variant="subtitle1"
              strong
              color="primary"
              marginRight="2x"
            />
            { isDesktop ? <null />
              : <Icon name="chevron-down" color="primary" />}
          </Box>
        )}

      {
          showCredito
            ? (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent={{ xs: 'flex-start', md: 'center' }}
                alignItems={{ xs: 'flex-start', md: 'center' }}
              >

                <CreditoRates />

                {/* Ver menos btn */}
                <Box
                  display="flex"
                  justifyContent={{ xs: 'left', md: 'center' }}
                  paddingRight={{ xs: '40px', md: '0' }}
                  marginTop={{ xs: '45px', md: '30px' }}
                  alignItems="center"
                  onClick={() => { showLessButton(); }}
                  style={{ cursor: 'pointer' }}
                >
                  <Typography
                    intlKey="RATES.SHOW.LESS"
                    strong
                    color="primary"
                    marginRight="2x"
                  />
                  <Icon name="chevron-up" color="primary" />
                </Box>

              </Box>
            ) : <null />
        }

      {/* RATES MODAL OPEN */}

      {isOpen && typeof document !== 'undefined' && createPortal(
        <RatesModal
          closeModal={closeModal}
          hideCuenta
        />, document.getElementById('modal-root'),
      )}

    </Box>
  );
};

export default Credito;
