/* eslint-disable no-restricted-properties */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from '@nubank/nuds-web/components/Image/Image';
import Link from '@nubank/nuds-web/components/Link/Link';
import Box from '@nubank/nuds-web/components/Box/Box';

import { StyledLink } from './StyledLink';
import { StyledTypography } from './StyledTypography';

function calculateDistance(
  element,
  mouseX,
  mouseY,
) {
  return Math.floor(
    Math.sqrt(Math.pow(mouseX
      - (element.offsetLeft + (element.offsetWidth / 2)), 2)
      + Math.pow(mouseY - (element.offsetTop + (element.offsetHeight / 2)),
        2)),
  ) - (element.offsetWidth / 2);
}

const pluginSettings = {
  proximity: 40,
  magnetism: 5,
};

function CardDesktop({
  name,
  href,
  imageLg,
  alt,
  gradient,
  color,
  id,
}) {
  const attractHover = e => {
    const element = document.querySelector(`.attract-hover-${id}`);

    if (element) {
      const mouseX = e.pageX;
      const mouseY = e.pageY;
      const distance = calculateDistance(element, mouseX, mouseY);

      const relativeX = (mouseX - (element.offsetLeft + (element.offsetWidth / 2)));
      const relativeY = (mouseY - (element.offsetTop + (element.offsetHeight / 2)));

      if (distance < pluginSettings.proximity) {
        element.classList.add('attract-hover-attracted');
        const children = element.children[0];
        children.style.transform = `translate(${relativeX / pluginSettings.magnetism}px, ${relativeY / pluginSettings.magnetism}px)`;
      } else {
        element.classList.add('attract-hover-attracted');
        const children = element.children[0];
        children.style.transform = 'translate(0)';
      }
    }
  };

  useEffect(() => {
    window.addEventListener('mousemove', attractHover);

    return () => {
      window.removeEventListener('mousemove', attractHover);
    };
  }, []);

  return (
    <>
      <Box
        className={`attract-hover-${id}`}
        display="inline-block"
      >
        <Box
          className="attract-hover-easing"
          transition="all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)"
        >
          <Link href={href} noStyle>
            <Image
              src={imageLg}
              webP={false}
              alt={alt}
              maxWidth="auto"
              height={{ md: '223px', lg: '100%' }}
              width={{ xs: 'auto', lg: '100%' }}
            />
          </Link>
        </Box>
      </Box>

      <Link href={href} noStyle>
        <StyledTypography
          gradient={gradient}
          marginTop="12x"
          marginBottom="8x"
          variant="heading3"
          whiteSpace={{ md: 'pre-line', lg: 'normal' }}
          textAlign="center"
        >
          {name}
        </StyledTypography>
      </Link>

      <StyledLink
        variant="action"
        href={href}
        width="fit-content"
        color={color}
        iconProps={{ name: 'arrow-up-right' }}
        gradient={gradient}
      >
        Conoce más
      </StyledLink>
    </>
  );
}

CardDesktop.propTypes = {
  alt: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  gradient: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  imageLg: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default CardDesktop;
