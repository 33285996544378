import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';

import Rates from '../../components/Rates/Rates';
import FAQSection from '../../components/FAQ/FAQ';

import MasterBrandHero from './patterns/Hero/MasterBrandHero';
import MultiProductHero from './patterns/Hero/MultiProductHero';
import Download from './patterns/Download/Download';
import CardsCarousel from './patterns/CardsCarousel/CardsCarousel';
import GradientNumbers from './patterns/GradientNumbers/GradientNumbers';
import SecurityAndTransparency from './patterns/SecurityAndTransparency/SecurityAndTransparency';
import Press from './patterns/Press/Press';
import Xpeer from './patterns/Xpeer/Xpeer';
import questions from './faq.json';

const MULTIPRODUCT_CAMPAIGN_EXPERIMENT_ID = '341514';
const MULTIPRODUCT_CAMPAIGN_EXPERIMENT_VARIANT = '42893';

function HomeLite() {
  const activeAutofillVariant = useFigPiiExperiment(MULTIPRODUCT_CAMPAIGN_EXPERIMENT_ID);

  return (
    <ParallaxProvider>
      {
      activeAutofillVariant === MULTIPRODUCT_CAMPAIGN_EXPERIMENT_VARIANT ? (
        <MultiProductHero />) : (
          <MasterBrandHero />
      )
      }
      <Download />
      <CardsCarousel />
      <GradientNumbers />
      <SecurityAndTransparency />
      <Rates />
      <Press />
      <FAQSection
        questions={questions}
        openLast={false}
        openFirst
      />
      <Xpeer />
    </ParallaxProvider>
  );
}

export default HomeLite;
